import * as React from 'react'
import { Link } from 'gatsby'

import IndexLayout from '../layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <div></div>
  </IndexLayout>
)

export default NotFoundPage
